$color-wenge: #66545E;
$color-beige: #F1EBE5;
$color-darkbrown: #3A241A;
$color-darkorange: #B04008;
$color-rose: #fa4251;
$color-aqua: #64c8c8;
$sidebarwd: 18rem;
$contentwd: 54rem;

$mediawd: (
  mobile: 320px,
  tablet: 769px,
  desktop: 1025px,
  wide: 1441px,
) !default;