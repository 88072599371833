.toc {
  position:fixed;
  top:6rem;
  display: none;
}

.toc-header {
  font-size:1.25rem;
  font-weight:600;
  padding:0 0 4px 12px;
  border-bottom:burlywood 2px solid;

  svg { 
    width:16px;
    display:inline-flex;
    transform:translateY(2px);
  }
}

.toc-board {
  max-width:#{$sidebarwd};
  margin: 1rem 0 1rem 2rem !important;
}

.toc-board, .toc-list {
  font-size:1.125rem;
  padding:0;
  margin: 0 auto;

  li a { display:inline-block; }
  li:last-child { margin-bottom:0; }

  .node-name--H1 { margin-top:8px; }
  .node-name--H2 {
    font-size:1rem;
    margin-top:6px;
    padding-left:16px;
  }

  .toc-list-item {
    word-break:normal;
    line-height:150%;

    a { 
      animation:.5s ease-out 0s 1 slide;
      cursor: pointer;
    }

    @keyframes slide {
      0% { transform:scaleY(0); }
      100% { transform:scaleY(1); }
    }
  }
   
  .is-active-link{
    color:indianred !important;
    font-weight:950;
  }
}

@include mq(wide){
  .toc{ display: block; }
}