#navigation {
  display:none;
  flex:1 1 auto;
  width:100%;

  &.nav-open {
    display:block;
    border-top:1px solid dimgrey;
  }
}

.nav-list {
  padding:0;
  margin:0 auto;

  li {
    font-size:1rem;

    > .nav-list {
      padding-left:18px;
      background:#eee;

      transition:max-height .5s ease-in-out, opacity .3s;
      opacity:0;
      max-height:0;
  
      li { position:relative; }
    }

    &.active {
      > .nav-item .nav-list-expander svg { transform:rotate(180deg); }

      > .nav-list {
        opacity:1;
        max-height:768px;
      }
    }
  }

  svg { 
    margin-right:8px;
    display:inline-block;
    vertical-align:middle;
  }
}

.nav-item {
  position:relative;
  padding:12px;
  border-bottom:1px solid #aaa;

  &.selected {
    span {
      font-weight:600;
      text-decoration:underline;
    }
  }

  a { font-weight:400; }
}

.nav-list-link {
  position:relative;
  transition:.2s ease;

  span { position:relative; }
}

.nav-list-expander {
  position:absolute;
  height:100%;
  aspect-ratio:1;
  top:0;
  right:8px;
  cursor:pointer;

  &, svg { transition:.3s; }
  svg { width:18px; }

  &:hover {
    transform:scale(1.25);
    svg { fill:$color-darkorange; }
  }
}

.nav-lh svg {
  margin-left:-0.25rem;
  position:relative;
  fill:$color-darkorange;
  transform:translateY(-2px);
}
.nav-sh svg { 
  width:14px;
  margin-left:0;
}

@include mq(tablet) {

.nav-list-link { min-height:2rem; }
.nav-list li { font-size:1.125rem; }
.nav-list-expander svg { width:20px; }

.nav-sh svg { width:16px; }
.nav-lh svg { width:18px; }

}

@include mq(desktop){

#navigation {
  width:calc(#{$sidebarwd} - 2.5rem);
  display:block;
  max-height:calc(100vh - 26rem);
  padding:0 20px;
  overflow-y:auto;
  overflow-x:hidden;
  margin-top:2rem;

  &.nav-open { border:none; }

  &::-webkit-scrollbar {
    width:4px;
    background-color:#aaa;
  }
  &::-webkit-scrollbar-thumb { background-color:rosybrown; }
}

.nav-item { border:none; }

.nav-list { position:relative; }
.nav-list li > .nav-list { background:none; }
.nav-list-link {
  display:flex;
  width:95%;
  align-items:center;

  &:hover {
    span {
      font-weight:600;
      text-decoration:underline;
    }

    transform:scale(.9);
  }
}

.nav-lh {
  &:before {
    content:"";
    position:absolute;
    display:block;
    left:0;
    border-radius:1.5rem;
    background:rgba(gold, .375);
    width:3rem;
    height:3rem;
    transition:.3s;
  }
  &:hover:before {
    width:105%;
    background:gold;
  }

  svg { transform:none; }
}

.nav-list-expander {
  background-color:transparent;
  right:-16px;
}

}