@mixin mq($name) {
  // Retrieves the value from the key
  $value:map-get($mediawd, $name);

  // If the key exists in the map
  @if $value {
    // Prints a media query based on the value
    @media (min-width:$value) {
      @content;
    }
  } @else {
    @warn "No value could be retrieved from `#{$mediawd}`. Please make sure it is defined in `$mediawd` map.";
  }
}

#loading
{
  width:100%;
  height:100%;
  top:0;
  left:0;
  position:fixed;
  display:flex;
  background:white;
  z-index:999;
  text-align:center;

  .loading_box {
    margin:auto;

    img {
      width:128px;
      height:128px;
    }

    p {
      margin:2rem;
      font-size:2.5rem;
      color:$color-wenge;
      font-weight:600;
      font-family:Cambria, sans-serif;
    }
  }
}

#stars {
  display:none;
  position:fixed;
  width:100%;
  height:100vh;
  z-index:-1;
  background:none;
}

@include mq(desktop){
  #stars { display:block; }
}